import { Button } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WInput from "../../components/w-input/w-input";
import WSelect from "../../components/w-select/w-select";
import { message } from "antd";
import { BASE_URL } from "../../baseURL";
import { useUser } from "context/UserContext";

function NewUser() {
  const { user } = useUser();
  let { id } = useParams();
  let navigate = useNavigate();
  let token = localStorage.getItem("token");

  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");

  const userTypes = [
    { value: "sales", name: "Member" },
    { value: "organization", name: "Admin" },
  ];

  useEffect(() => {
    if (user.user_type === "sales") {
      navigate("/");
    }
    id !== undefined && getUser();
  }, []);

  const getUser = async () => {
    return await fetch(`${BASE_URL}/users/show/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((userData) => {
        setName(userData.full_name);
        setRole(userData.user_type);
        setEmail(userData.email);
        return userData;
      })
      .catch((error) => {
        message.error("Lead not found");
        console.error(error.message);
      });
  };

  const createUser = async () => {
    return await fetch(`${BASE_URL}/create_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        full_name: name,
        user_type: role,
        email: email,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        message.success("User created successfully");
        return json;
      })
      .catch((error) => {
        message.error("Lead not found");
        console.error(error.message);
      });
  };

  const updateUser = async () => {
    return await fetch(`${BASE_URL}/update_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        id: id,
        full_name: name,
        user_type: role,
        email: email,
      }),
    })
      .then((response) => response.json())
      .then((userData) => {
        setName(userData.full_name);
        setRole(userData.user_type);
        setEmail(userData.email);
        message.success("User updated successfully");
        return userData;
      })
      .catch((error) => {
        message.error("Lead not found");
        console.error(error.message);
      });
  };

  const saveChanges = () => {
    if (id === undefined) {
      createUser();
    } else {
      updateUser();
    }
  };

  return (
    <div className="container-lg">
      <div
        className="title-purple"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #ECECEC",
          paddingBottom: 5,
          marginBottom: 15,
        }}
      >
        {id === undefined ? "New User" : "Update User"}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <div style={{ width: "95%" }}>
            <WInput
              label="Full Name:"
              value={name}
              placeholder="John"
              style={{ marginBottom: 20 }}
              onChangeValue={(value) => setName(value)}
            />
            <WInput
              label="Work Email:"
              value={email}
              placeholder="john.doe@weyzo.io"
              disabled={id != undefined}
              style={{ marginBottom: 20 }}
              onChangeValue={(value) => setEmail(value)}
            />
          </div>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <div style={{ width: "95%" }}>
            <WSelect
              style={{ marginBottom: 20 }}
              value={role}
              placeHolder={"Sales"}
              label="User Role:"
              options={userTypes}
              onChange={(value) => setRole(value)}
            />
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button
          style={{ marginLeft: "auto" }}
          size="large"
          className="primary-btn font-regular"
          onClick={() => saveChanges()}
        >
          {id === undefined ? "Create User" : "Update User"}
        </Button>
      </div>
    </div>
  );
}

export default NewUser;
