import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm, Controller, FormProvider, useWatch } from "react-hook-form";
import { Input, Collapse, Skeleton, Tag, Switch, Tooltip } from "antd";
import { LeftOutlined, LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as Cam } from "assets/img/camera-video.svg";

import { useUser } from "../../context/UserContext";

import Modal from "components/Modals/Modal/Modal";

import PanelIcon from "./components/PanelIcon";
import DesignPanel from "./components/DesignPanel";
import CtaButtonPanel from "./components/CtaButtonPanel";
import FixedQuestionsPanel from "./components/FixedQuestionsPanel";
import CustomQuestionsPanel from "./components/CustomQuestionsPanel";
import QualifyingQuestionsPanel from "./components/QualifyingQuestionsPanel";
import LegalPanel from "./components/LegalPanel";
import MeetingPanel from "./components/MeetingPanel";
import Watermark from "./components/Watermark";

import FixedQuestionsComp from "./components/comp-preview/FixedQuestionsComp";
import CustomQuestionsComp from "./components/comp-preview/CustomQuestionsComp";
import LegalComp from "./components/comp-preview/LegalComp";
import QualifyingQuestionsPreview from "./components/comp-preview/QualifyingQuestionsPreview";

import weyzoLogo from 'assets/img/weyzo_logo.svg';
import weyzoLogoShort from "../../assets/img/formBuilder/weyzoLogo.png";

import styles from "./formBuilder.module.css";

const { Panel } = Collapse;

const Form = (
  {
    formData,
    loading,
    logo,
    setLogo,
    fixedQuestions,
    setFixedQuestions,
    customFixedQuestions,
    setCustomFixedQuestions,
    qualifyingQuestions,
    setQualifyingQuestions,
    onDraft,
    onPublish
  }
) => {
  const { plan } = useUser();
  const methods = useForm();
  const navigate = useNavigate();

  const title = methods.watch("title");
  const subtitle = methods.watch("subtitle");
  const [disabledWatermark, setDisabledWatermark] = useState(formData.watermark_disabled);
  const [publishButtonText, setPublishButtonText] = useState("");
  const [formPrimaryColor, setFormPrimaryColor] = useState(formData.primary_color || "#8847E6");
  const [formSecondaryColor, setFormSecondaryColor] = useState(formData.secondary_color || "#FCF9F0");
  const [modalTitle, setModalTitle] = useState("")
  const [modalPrimaryButtonText, setModalPrimaryButtonText] = useState("")
  const [modalSecondaryButtonText, setModalSecondaryButtonText] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [toPublish, setToPublish] = useState(false)

  useEffect(() => {
    setFormPrimaryColor(formData.primary_color);
    setFormSecondaryColor(formData.secondary_color)
    setPublishButtonText(formData.status === 'active' ? 'Update' : 'Publish');
    setDisabledWatermark(formData.watermark_disabled);
  }, [formData]);

  const onBack = () => {
    setToPublish(false);
    setModalTitle('Do you want to save the form as a draft?')
    setModalSecondaryButtonText('Discard Changes')
    setModalPrimaryButtonText(formData.status === 'active' ? 'Update' : 'Save as Draft')
    setShowModal(true);
  };

  const onPublishForm = (event) => {
    event.preventDefault();
    setToPublish(true);
    setModalTitle('Do you want to save and publish the form?')
    setModalSecondaryButtonText('Cancel')
    setModalPrimaryButtonText('Publish')
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onSubmitModal = () => {
    toPublish ?
    methods.handleSubmit(onPublish)() :
    methods.handleSubmit(onDraft)();

    setShowModal(false);
  };

  const onDiscardChanges = () => {
    setShowModal(false);
    navigate("/forms", { state: { from: "DiscardForm"} });
  };

  const PreviewButton = ({ control }) => {
    const buttonBackgroundColor = useWatch({
      control,
      name: "button-background-color",
    });
    const buttonTextColor = useWatch({
      control,
      name: "button-text-color",
    });
    const buttonText = useWatch({
      control,
      name: "button-text",
    });
    return (
      <>
        <div
          className={styles["preview-button"]}
          style={{
            backgroundColor: buttonBackgroundColor ? buttonBackgroundColor.hex : formData.button_background,
          }}
        >
          <p style={{
            color: buttonTextColor ? buttonTextColor.hex : formData.button_text_color,
          }}>
            { buttonText ? buttonText : formData.button_text }
          </p>
        </div>
      </>
    );
  };

  const backgroundColor = useWatch({
    control: methods.control,
    name: "design-secondary-color",
  });

  const onDeleteQualyQuestion = (e) => {
    if (e.target.parentElement.id) {
      setQualifyingQuestions((prev) => {
        return prev.map((item) =>
          item.key === e.target.parentElement.id ? { ...item, active: false } : item
        );
      });
    }
  };

  const renderTopMenu = () => {
    return (
      <>
        <div className={styles["top-menu-container"]}>
          <div className={styles["top-menu-left"]}>
            <img
              className={styles["logo-weyzo"]}
              src={weyzoLogoShort}
              alt="weyzo-logo-short"
            />
            <div className={styles["top-menu-left-text"]} onClick={onBack}>
              <LeftOutlined />
              <p>Forms</p>
              { formData.status && (
                <p> <Tag color={formData.status === 'active' ? 'success' : 'default'}>{formData.status}</Tag> </p>
              )}
            </div>
          </div>
          {
            formData.status && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Cam style={{ marginRight: 16 }} />
                <Controller
                  name="video_call"
                  control={methods.control}
                  defaultValue={formData.video_call}
                  render={({ field }) => (
                    <Switch
                      disabled={formData.calendar_disabled}
                      checked={field.value}
                      onChange={(event) => field.onChange(event)}
                    />
                  )}
                />
                {
                  formData.calendar_disabled && (
                    <Tooltip placement="bottom" title="To activate video calls, you need to sync at least one user calendar.">
                      <InfoCircleOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
                    </Tooltip>
                  )
                }
              </div>
            )
          }
        </div>
      </>
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <main
          id="form-builder"
          className={styles.main}
          style={{
            backgroundColor: backgroundColor
              ? backgroundColor.hex
              : formSecondaryColor,
          }}
        >
          <div
            id="form"
            className={styles.container}
            onSubmit={(event) => onPublishForm(event)}
          >
            {renderTopMenu()}
            <form>
              <Controller
                name="title"
                control={methods.control}
                defaultValue={formData.title}
                rules={{ required: 'Title is required' }}
                render={({ field, fieldState }) => (
                  <div>
                    <Input
                      size="large"
                      placeholder="Form Title"
                      className={`${styles.input} ${fieldState.invalid ? styles.input_error : ''}`}
                      {...field}
                    />
                    {fieldState.invalid && (
                      <div className={styles.input_error_message}>Title is required</div>
                    )}
                  </div>
                )}
              />
              <Controller
                name="subtitle"
                control={methods.control}
                defaultValue={formData.subtitle}
                render={({ field }) => (
                  <Input
                    size="large"
                    placeholder="Form Subtitle"
                    className={[styles.input]}
                    {...field}
                  />
                )}
              />
              <Collapse className={styles.collapse}>
                <Panel
                  id="panel-design"
                  className={styles["collapse-panel"]}
                  header={<PanelIcon icon="designIcon" text="Design" />}
                  key="1"
                  size="large"
                >
                  <DesignPanel 
                    setLogo={setLogo}
                    logo={logo}
                    primaryColor={formPrimaryColor}
                    secondaryColor={formSecondaryColor}
                    setDisabledWatermark={setDisabledWatermark}
                    disabledWatermark={disabledWatermark}
                    watermarkDisabled={plan === 'starter' ? true : false}
                  />
                </Panel>
                <Panel
                  id="panel-button"
                  className={styles["collapse-panel"]}
                  header={<PanelIcon icon="ctaIcon" text="CTA Button" />}
                  key="2"
                  size="large"
                >
                  <CtaButtonPanel
                    text={formData.button_text}
                    backgroundColor={formData.button_background}
                    textColor={formData.button_text_color}
                  />
                </Panel>
                <Panel
                  className={styles["collapse-panel"]}
                  header={
                    <PanelIcon icon="fixedIcon" text="Data collection fields" />
                  }
                  key="3"
                  forceRender={true}
                  collapsible={formData.status === 'active' ? 'disabled' : ""}
                >
                  <div>
                    Personalize your data collection fields. Add new personalized fields choosing the field type. Choose wich field is required or not requiered.
                  </div>
                  <FixedQuestionsPanel
                    fixedQuestions={fixedQuestions}
                    setFixedQuestions={setFixedQuestions}
                    customFixedQuestions={customFixedQuestions}
                    setCustomFixedQuestions={setCustomFixedQuestions}
                  />
                  <CustomQuestionsPanel
                    customFixedQuestions={customFixedQuestions}
                    setCustomFixedQuestions={setCustomFixedQuestions}
                  />
                </Panel>
                <Panel
                  className={styles["collapse-panel"]}
                  header={
                    <PanelIcon
                      icon="qualifyingIcon"
                      text="Qualifying Questions"
                    />
                  }
                  key="4"
                  collapsible={formData.status === 'active' ? 'disabled' : ""}
                >
                  <div>
                    This section will enable the AI to qualify leads more accurately.
                  </div>
                  <QualifyingQuestionsPanel
                    qualifyingQuestions={qualifyingQuestions}
                    setQualifyingQuestions={setQualifyingQuestions}
                    onDeleteQualyQuestion={onDeleteQualyQuestion}
                  />
                </Panel>
                <Panel
                  className={styles["collapse-panel"]}
                  header={
                    <PanelIcon icon="meetingIcon" text="Meeting Details" />
                  }
                  key="6"
                >
                  <MeetingPanel
                    duration={formData.meeting_duration}
                    title={formData.meeting_title}
                    description={formData.meeting_description}
                  />
                </Panel>
                <Panel
                  className={styles["collapse-panel"]}
                  header={<PanelIcon icon="legalIcon" text="Legal" />}
                  key="7"
                  collapsible={formData.status === 'active' ? 'disabled' : ""}
                >
                  <LegalPanel text={formData.legal_text} />
                </Panel>
              </Collapse>
              <div className={styles["buttons-container"]}>
                { formData.status !== 'active' && (
                  <button
                    onClick={methods.handleSubmit(onDraft)}
                    className={`${styles["button-draft"]} ${
                      loading && styles["button-disabled"]
                    }`}
                  >
                    {loading ? <LoadingOutlined /> : "Save as Draft"}
                  </button>
                )}
                <button
                  type="submit"
                  className={`${styles.button} ${
                    loading && styles["button-disabled"]
                  }`}
                >
                  {loading ? <LoadingOutlined /> : publishButtonText}
                </button>
              </div>
            </form>
          </div>
          <div id="preview" className={styles.preview}>
            <div className={styles.logoContainer}>
              {logo ? (
                <img
                  src={logo.startsWith('data:image') ? logo : `${process.env.REACT_APP_HOST_NAME}${logo}`}
                  alt="logo-preview" />
              ) : (
                <Skeleton.Image />
              )}
            </div>
            <p className={`${styles["preview-title"]} ${title ? styles['preview-normal'] : styles['preview-placeholder']}`}>
              {title ? title : "Form Title"}
            </p>
            <p className={`${styles["preview-subtitle"]}  ${subtitle ? styles['preview-normal'] : styles['preview-placeholder']}`}>
              {subtitle ? subtitle : "Form Subtitle"}
            </p>
            <div>
              <div>
                {fixedQuestions.map((question) => {
                  return (
                    <>
                      <FixedQuestionsComp
                        key={question.name}
                        control={methods.control}
                        question={question}
                      />
                    </>
                  );
                })}
                {customFixedQuestions
                  .filter((question) => question.active)
                  .map((question) => {
                    return (
                      <>
                        <CustomQuestionsComp
                          key={question.name}
                          control={methods.control}
                          question={question}
                        />
                      </>
                    );
                  })
                }
              </div>
            </div>
            <PreviewButton control={methods.control} />
            <LegalComp control={methods.control} />
            <div>
              {qualifyingQuestions
                .filter((question) => question.active)
                .map((question, index) => {
                  return (
                    <QualifyingQuestionsPreview
                      key={index}
                      control={methods.control}
                      question={question}
                      length={qualifyingQuestions.length}
                      index={index}
                    />
                  )
                })
              }
            </div>
            { !disabledWatermark && ( 
              <Watermark logo={weyzoLogo} />
            )}
          </div>
        </main>
      </FormProvider>
      <Modal
        open={showModal}
        onClose={onCloseModal}
        onSubmit={onSubmitModal}
        onDiscard={toPublish ? '' : onDiscardChanges}
        secondaryButtonText={modalSecondaryButtonText}
        primaryButtonText={modalPrimaryButtonText}
      >
        <p> { modalTitle } </p>
      </Modal>
    </>
  );
}

export default Form;
