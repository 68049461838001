import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar, Input, Table, Tabs } from "antd";
import { useUser } from "../../context/UserContext";
import moment from "moment";
import { AUTHENTICATED_URL } from "../../baseURL";
// ICONS
import { ReactComponent as Search } from "assets/img/icon_search.svg";
import { ReactComponent as Calendar } from "assets/img/calendar.svg";
import { ReactComponent as Clock } from "assets/img/clock.svg";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Loading from "components/Loading/Loading";

// STYLES
import styles from "./meetings.module.css";

const { TabPane } = Tabs;

function Meetings() {
  const { user } = useUser();
  const [pageLoading, setPageLoading] = useState(true);
  let token = localStorage.getItem("token");
  const [meetings, setMeetings] = useState([]);
  const [pastMeetings, setPastMeetings] = useState([]);

  const [meetingsFiltered, setMeetingsFiltered] = useState([]);
  const [pastMeetingsFiltered, setPastMeetingsFiltered] = useState([]);

  useEffect(() => {
    setPageLoading(true);
    getData();
  }, []);

  // Quick filter bar
  const searchText = async (string) => {
    var search = string.target.value.toLowerCase();

    // Filter past meetings
    const filteredData = pastMeetings.filter((el) => {
      return (
        el.lead.name.toLowerCase().includes(search) ||
        el.user.fullName.toLowerCase().includes(search)
      );
    });

    // Filter future meetings
    const filteredDataFuture = meetings.filter((el) => {
      return (
        el.lead.name.toLowerCase().includes(search) ||
        el.user.fullName.toLowerCase().includes(search)
      );
    });

    setPastMeetingsFiltered(filteredData);
    setMeetingsFiltered(filteredDataFuture);
  };

  const getData = async () => {
    try {
      const response = await axios.get(`${AUTHENTICATED_URL}/meetings`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const meetings = response.data.meetings;
      let past_meetings = [];
      let future_meetings = [];

      meetings.forEach((element) => {
        if (moment(element.date).isBefore()) past_meetings.push(element);
        else future_meetings.push(element);
      });
      setPastMeetings(past_meetings);
      setPastMeetingsFiltered(past_meetings);

      setMeetings(future_meetings);
      setMeetingsFiltered(future_meetings);
      setPageLoading(false);
      return meetings;
    } catch (error) {
      setPageLoading(false);
      console.error(error.message);
    }
  };

  const columns = [
    {
      title: "Qualification",
      dataIndex: "lead",
      key: "lead",
      render: (_, { lead }) => (
        <>
          {lead.status === "qualified" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 100,
                  backgroundColor: "var(--forthtiary)",
                  marginRight: 10,
                }}
              ></div>{" "}
              Qualified
            </div>
          )}
          {lead.status === "unqualified" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 100,
                  backgroundColor: "var(--danger)",
                  marginRight: 10,
                }}
              ></div>{" "}
              Disqualified
            </div>
          )}
          {lead.status === "uncompleted" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 100,
                  backgroundColor: "var(--info)",
                  marginRight: 10,
                }}
              ></div>{" "}
              Uncompleted
            </div>
          )}
        </>
      ),
    },
    {
      title: "AI scoring",
      dataIndex: "lead",
      key: "lead",
      render: (_, { lead }) => (
        <>
          <div>{lead.lead_scoring}</div>
        </>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "lead",
      key: "lead",
      render: (_, { lead }) => (
        <>
          <div>{lead.name}</div>
          <a href={"/leads/" + lead.id} style={{ color: "var(--primary)" }}>
            View Lead
          </a>
        </>
      ),
    },
    {
      title: "Job Title",
      dataIndex: "lead",
      key: "lead",
      render: (_, { lead }) => (
        <>
          <div>{lead.position}</div>
        </>
      ),
    },
    {
      title: "Meeting date",
      dataIndex: "date",
      key: "date",
      render: (_, { date }) => <>{moment(date).format("DD.MM.YYYY - H:mm")}</>,
    },
    {
      title: "Meeting link",
      dataIndex: "hangout_link",
      key: "hangout_link",
      render: (_, meeting) => (
        <>
          {new Date(meeting.date) >= new Date() ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={meeting.hangout_link}
              style={{ padding: "5%" }}
              className="primary-btn-light font-bold"
            >
              Join Meeting
            </a>
          ) : (
            <div>-</div>
          )}
        </>
      ),
    },
    {
      title: "Asigned To",
      dataIndex: "user",
      key: "user",
      ellipsis: true,
      render: (_, { user }) => (
        <>
          { user.image && <Avatar size="large" src={user.image} style={{ marginRight: 10 }} />}
          {user.fullName}
        </>
      ),
    },
  ];

  const paginationRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a
          style={{ display: "flex", alignItems: "center", marginRight: 15 }}
          className="font-medium"
        >
          <LeftOutlined style={{ marginRight: 5 }} />
          Previous
        </a>
      );
    } else if (type === "next") {
      return (
        <a
          style={{ display: "flex", alignItems: "center", marginLeft: 15 }}
          className="font-medium"
        >
          Next
          <RightOutlined style={{ marginLeft: 5 }} />
        </a>
      );
    } else if (type === "page") {
      return (
        <a
          style={{ color: "var(--gray-placeholder-dark)" }}
          className="font-bold"
        >
          {_}
        </a>
      );
    } else {
      return originalElement;
    }
  };

  const renderLoading = () => {
    return (
      <>
        {pageLoading && (
          <div className="weyzo-loader-page-wrapper">
            <Loading />
          </div>
        )}
      </>
    );
  };

  if (pageLoading) {
    return renderLoading();
  }

  return (
    <>
      <div style={{ marginBottom: 20, display: "flex", flexDirection: "row" }}>
        <div style={{ flexGrow: 1 }}>
          <Input
            size="large"
            className={[styles.inputSearch, "font-medium"]}
            inputMode="search"
            onChange={(e) => searchText(e)}
            placeholder="Search meetings"
            prefix={<Search />}
          />
        </div>
        <div style={{ flexGrow: 1 }}></div>
      </div>
      <div className="container-lg">
        <Tabs defaultActiveKey="1" centered type="line">
          <TabPane
            tab={
              <span style={{ display: "flex", alignItems: "center" }}>
                <Calendar style={{ marginRight: 10 }} />
                <span className="font-medium">Upcoming Meetings</span>
              </span>
            }
            key="1"
          >
            <Table
              columns={columns}
              dataSource={meetingsFiltered}
              rowKey="id"
              pagination={{
                position: ["bottomCenter"],
                itemRender: paginationRender,
                total: meetings.length,
                showSizeChanger: false,
              }}
            />
          </TabPane>
          <TabPane
            tab={
              <span style={{ display: "flex", alignItems: "center" }}>
                <Clock style={{ marginRight: 10 }} />
                <span className="font-medium">Past Meetings</span>
              </span>
            }
            key="2"
          >
            <Table
              columns={columns}
              dataSource={pastMeetingsFiltered}
              rowKey="id"
              pagination={{
                position: ["bottomCenter"],
                itemRender: paginationRender,
                total: meetings.length,
                showSizeChanger: false,
              }}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default Meetings;
