import { AUTHENTICATED_URL } from "baseURL"

import axios from 'axios'

const token = localStorage.getItem('token')

const apiInstance = axios.create({
    baseURL: AUTHENTICATED_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    }
})

export const checkPlan = async id => {
    let response = null
    try {
        response = await apiInstance.get(`/check_plan/${id}`)
    } catch (err) {
        console.error(err.message)
        response = err.response
    }
    return response.data
}

export const getStripeUrl = async () => {
    let response = null;
    try {
        response = await apiInstance.get('/payments/create_stripe_checkout_session');
    } catch (err) {
        console.error('Error fetching Stripe URL:', err);
        response = err.response;
    }

    return response;
};

export const getBillingPortalUrl = async () => {
    let response = null;
    try {
        response = await apiInstance.get('/payments/create_stripe_customer_portal_session');
    } catch (err) {
        console.error('Error fetching Billing Portal URL:', err);
        response = err.response;
    }
    return response
};
