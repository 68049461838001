import { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AUTHENTICATED_URL } from "../../baseURL";
import { Avatar, Input, Table, Tabs, Progress } from "antd";
import {
  UpgradeLeadsButton,
} from "components/Buttons/Buttons";

import Loading from "components/Loading/Loading";
import ModalUpgradePlan from "components/Modals/ModalUpgradePlan/ModalUpgradePlan";
// ICONS
import { ReactComponent as Search } from "assets/img/icon_search.svg";
import { ReactComponent as AllLeads } from "assets/img/lead/all_leads_selected_icon.svg";
import { ReactComponent as Qualified } from "assets/img/lead/qualified_arrow.svg";
import { ReactComponent as Disqualified } from "assets/img/lead/disqualified_arrow.svg";
import { ReactComponent as Uncompleted } from "assets/img/lead/uncompleted.svg";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import info from "./info-circle.svg";
// STYLES
import styles from "./leads.module.css";
import { useUser } from "../../context/UserContext";

const { TabPane } = Tabs;

function Leads() {
  const { user, plan } = useUser();
  let token = localStorage.getItem("token");
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [open, setOpen] = useState(false);
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    getData();
    console.log("user authenticated at leads");
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(`${AUTHENTICATED_URL}/leads`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const leads = response.data.leads;
      setLeads(leads);
      setFilteredLeads(leads);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.error(error.message);
    }
  };

  // Quick filter bar
  const searchText = async (string) => {
    var str = string.target.value.toLowerCase();
    setSearch(str);

    // Filter by name, email or phone
    var filteredData = leads.filter((el) => {
      return (
        el.name.toLowerCase().includes(str) ||
        el.position.toLowerCase().includes(str) ||
        el.email.toLowerCase().includes(str) ||
        el.phone.toLowerCase().includes(str)
      );
    });

    if (status && status.trim() !== "" && status !== null) {
      filteredData = filteredData.filter((el) => {
        return el.status.toLowerCase().includes(status);
      });
    }

    setFilteredLeads(filteredData);
  };

  // filter by status
  const filterQualification = async (status_requested) => {
    if (status === status_requested || status_requested === "all") {
      status_requested = "";
    }

    setStatus(status_requested);

    // Filter by name, email or phone
    var filteredData = leads.filter((el) => {
      return (
        el.name.toLowerCase().includes(search) ||
        el.position.toLowerCase().includes(search) ||
        el.email.toLowerCase().includes(search) ||
        el.phone.toLowerCase().includes(search)
      );
    });

    // Filter past meetings
    filteredData = filteredData.filter((el) => {
      return el.status.toLowerCase().includes(status_requested);
    });

    setFilteredLeads(filteredData);
  };

  const columns = [
    {
      title: "Qualification",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          {status === "qualified" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 100,
                  backgroundColor: "var(--forthtiary)",
                  marginRight: 10,
                }}
              ></div>{" "}
              Qualified
            </div>
          )}
          {status === "unqualified" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 100,
                  backgroundColor: "var(--danger)",
                  marginRight: 10,
                }}
              ></div>{" "}
              Not Qualified
            </div>
          )}
          {status === "uncompleted" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 10,
                  width: 10,
                  borderRadius: 100,
                  backgroundColor: "var(--info)",
                  marginRight: 10,
                }}
              ></div>{" "}
              Uncompleted
            </div>
          )}
        </>
      ),
    },
    {
      title: "AI scoring",
      dataIndex: "lead_scoring",
      key: "lead_scoring",
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Work email",
      dataIndex: "email",
      key: "work_email",
    },
    {
      title: "Job Title",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Form",
      dataIndex: "form",
      key: "form",
      render: (_, { form }) => (
        <>
          {form && form.title}
          {!form && "No form"}
        </>
      ),
    },
    {
      title: "Meeting Link",
      dataIndex: "meeting_link",
      key: "meeting_link",
      render: (_, { meeting_link, meeting_date }) => (
        <>
          {meeting_link && (
            <>
              <p style={{marginBottom: 0}}> {moment(meeting_date).format("DD.MM.YYYY - H:mm")} </p>
              <a
                href={meeting_link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "var(--primary)", fontSize: 12 }}
              >
                View Meeting
              </a>
            </>
          )}
          {!meeting_link && "No meeting"}
        </>
      ),
    },
    {
      title: "Assigned to",
      dataIndex: "user",
      key: "user",
      ellipsis: true,
      render: (_, { user }) => (
        <>

          {user && user.image && user.image.includes("amazonaws") && (
            <Avatar size="large" src={user.image} style={{ marginRight: 10 }} />
          )}
          {user ? user.full_name : "Not assigned"}
        </>
      ),
    },
  ];

  const renderLoading = () => {
    return (
      <>
        {pageLoading && (
          <div className="weyzo-loader-page-wrapper">
            <Loading />
          </div>
        )}
      </>
    );
  };

  if (pageLoading) {
    return renderLoading();
  }

  const leadsPlanLimit = {
    starter: 1000,
    professional: 5000,
    enterprise: 10000,
  }

  const renderPlanLimitation = () => {
    if (plan !== "starter") { return <></>; }

    const progressStyle = {
      width: "200px",
      marginLeft: "40px",
      marginRight: "20px",
    };
    return (
      <>
        <div className={styles["limit-container"]}>
          <img src={info} alt="info icon" />
          <p className={styles["limit-leads"]}>
            <span>Leads</span> limit in Starter plan
          </p>
          <Progress
            style={progressStyle}
            showInfo={false}
            percent={leads.length}
            strokeColor="#8847E6"
          />
          <p className={styles["limit-percent-number"]}>
            <span style={{ color: "#8847E6" }}>{leads.length}</span> / {leadsPlanLimit[plan]}
          </p>
          <UpgradeLeadsButton>Upgrade your plan</UpgradeLeadsButton>
        </div>
      </>
    );
  };

  const paginationRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a
          style={{ display: "flex", alignItems: "center", marginRight: 15 }}
          className="font-medium"
        >
          <LeftOutlined style={{ marginRight: 5 }} />
          Previous
        </a>
      );
    } else if (type === "next") {
      return (
        <a
          style={{ display: "flex", alignItems: "center", marginLeft: 15 }}
          className="font-medium"
        >
          Next
          <RightOutlined style={{ marginLeft: 5 }} />
        </a>
      );
    } else if (type === "page") {
      return (
        <a
          style={{ color: "var(--gray-placeholder-dark)" }}
          className="font-bold"
        >
          {_}
        </a>
      );
    } else {
      return originalElement;
    }
  };

  const renderTable = () => {
    return (
      <>
        { renderPlanLimitation() }
        <Table
          onRow={(record, rowIndex) => {
            if (!record.visible) {
                return {
                  onClick: (event) => {
                    onOpenModal()
                  }
                }
              } else {
                return {
                  onClick: (event) => {
                    navigate("/leads/" + record.id);
                  },
                };
              }
            }
          }
          rowClassName={(record, rowIndex) => {
            return (record.visible ? '' : styles.blurred)
          }}
          columns={columns}
          dataSource={filteredLeads}
          rowKey="id"
          style={{ cursor: "pointer" }}
          pagination={{
            position: ["bottomCenter"],
            itemRender: paginationRender,
            total: filteredLeads.length,
            showSizeChanger: false,
          }}
        />
      </>
    )
  }

  const onOpenModal = () => {
    setOpen(true)
  }

  const onCloseModal = () => {
    setOpen(false)
  }

  return (
    <>
      <ModalUpgradePlan open={open} onClose={onCloseModal}>
        To unlock this new leads you have to Upgrade to the Professional plan.
      </ModalUpgradePlan>
      <div style={{ marginBottom: 20, display: "flex", flexDirection: "row" }}>
        <div style={{ flexGrow: 1 }}>
          <Input
            size="large"
            className={[styles.inputSearch, "font-medium"]}
            inputMode="search"
            onChange={(e) => searchText(e)}
            placeholder="Search Leads"
            prefix={<Search />}
          />
        </div>
        <div style={{ flexGrow: 1 }}></div>
      </div>
      <div
        style={{
          boxShadow: "var(--shadow-lg)",
          padding: 20,
          backgroundColor: "white",
          borderRadius: 8,
        }}
      >
        <Tabs defaultActiveKey="1" centered type="line">
          <TabPane
            tab={
              <span 
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => filterQualification("all")}
              >
                <AllLeads style={{ marginRight: 10 }} />
                <span className="font-medium">All Leads</span>
              </span>
            }
            key="1"
          >
            { renderTable() }
          </TabPane>
          <TabPane
            tab={
              <span 
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => filterQualification("qualified")}
              >
                <Qualified style={{ marginRight: 10 }} />
                <span className="font-medium">Qualified</span>
              </span>
            }
            key="2"
          >
            { renderTable() }
          </TabPane>
          <TabPane
            tab={
              <span 
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => filterQualification("unqualified")}
              >
                <Disqualified style={{ marginRight: 10 }} />
                <span className="font-medium">Disqualified</span>
              </span>
            }
            key="3"
          >
            { renderTable() }
          </TabPane>
          <TabPane
            tab={
              <span 
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => filterQualification("uncompleted")}
              >
                <Uncompleted style={{ marginRight: 10 }} />
                <span className="font-medium">Uncompleted</span>
              </span>
            }
            key="4"
          >
            { renderTable() }
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default Leads;
