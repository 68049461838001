import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ExportOutlined } from "@ant-design/icons";

import Union from "../../assets/img/Union.svg";

import styles from "./TrialExpired.module.css";

const TrialExpired = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 30,
        }}
      >
        <img
          style={{ width: "auto", height: "auto", marginBottom: 140 }}
          src={Union}
          alt="Union"
        />
          <>
            <h1 className={"font-bold"} style={{ fontSize: 30 }}>
              Your trial has expired
            </h1>
            <Button
              size="large"
              className="primary-btn font-regular"
              onClick={() => navigate('/upgrade-plan')}
            >
              Manage Plans
              <ExportOutlined style={{marginLeft: 8, color: "#FFFFFF" }} />
            </Button>
          </>
      </div>
    </>
  );
}

export default TrialExpired;
