import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Avatar, Button, Divider, message } from "antd";
import { PlusOutlined, RobotOutlined, ExportOutlined } from "@ant-design/icons";
import { useUser } from "../../context/UserContext";
import Moment from "moment";
import { AUTHENTICATED_URL } from "../../baseURL";
import { translate_user_type } from "services/helper";

import WInput from "components/w-input/w-input";
import WTextarea from "components/WTextarea/WTextarea";
import ModalUpgradePlan from "components/Modals/ModalUpgradePlan/ModalUpgradePlan";
import Loading from "components/Loading/Loading";

import { ReactComponent as ChevronLeftPurple } from "assets/img/profile/chevron-left-purple.svg";

import styles from "./CompanyProfile.module.css";

function CompanyProfile() {
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  const { user, plan } = useUser();
  const [pageLoading, setPageLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [companyState, setCompanyState] = useState(null);
  const [editCompany, setEditCompany] = useState(true);
  const [upgradeModalopen, setUpgradeModalOpen] = useState(false);
  const inputHidden = useRef(null);

  useEffect(() => {
    setPageLoading(true);
    getCompany()
    console.log("User authenticated at Organization profile");
  }, []);

  const getCompany = async () => {
    try {
      const response = await axios.get(`${AUTHENTICATED_URL}/company`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const companyData = response.data.company;
      setCompanyState(companyData);
      setPageLoading(false);
      return companyData;
    } catch (error) {
      setPageLoading(true);
      message.error("Error getting company data");
      console.error(error.message);
      throw error;
    }
  };

  const updateCompanyImage = useCallback(async (imageData) => {
    if (!imageData) return;
    if (imageData.size > 1048576) {
      message.error("Image size is too large. Max size is 1MB");
      return;
    }
    const formData = new FormData();
    if (imageData) {
      formData.append('company[image]', imageData);
    } else {
      formData.append('company[image]', '');
    }

    try {
      const response = await axios.patch(`${AUTHENTICATED_URL}/company`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setCompanyState((prevCompany) => ({
        ...prevCompany,
        image_url: response.data.company.image_url,
      }));
      setEditCompany(true);
      message.success(response.data.message);
      return response.data;
    } catch (error) {
      message.error("Error updating user");
      console.error(error.message);
      throw error;
    }
  }, [user, setCompanyState, token]);

  const getImageCompany = () => {
    inputHidden.current.click();
  };
  
  const updateCompany = async () => {  
    let companyParams = {
      id: companyState.id,
      address: companyState.address,
      city: companyState.city,
      country: companyState.country,
      industry: companyState.industry,
      linkedin: companyState.linkedin,
      name: companyState.name,
      phone: companyState.phone,
      postal_code: companyState.postal_code,
      province: companyState.province,
      website: companyState.website
    };
  
    try {
      const response = await axios.patch(`${AUTHENTICATED_URL}/company`, { company: companyParams }, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      const companyData = response.data.company;
      setCompanyState(companyData);
      setEditCompany(true);
      message.success("Company updated successfully");
      return companyData;
    } catch (error) {
      message.error("Error updating company");
      console.error(error.message);
      throw error;
    }
  };

  const addNewUser = () => {
    if (isStarterPlan(plan)) {
      setUpgradeModalOpen(true);
      return;
    }
    navigate("/user_company");
  };

  const isStarterPlan = (plan) => {
    return plan === "starter";
  };
  
  const onCloseModal = () => {
    setUpgradeModalOpen(false);
  };

  const renderLoading = () => {
    return (
      <>
        {pageLoading && (
          <div className="weyzo-loader-page-wrapper">
            <Loading />
          </div>
        )}
      </>
    );
  };

  if (pageLoading) {
    return renderLoading();
  }

  return (
    user !== null &&
    companyState !== null && (
      <>
        <ModalUpgradePlan open={upgradeModalopen} onClose={onCloseModal}>
          You have a free trial Weyzo account which allows you to create only 1-5 user.
          Want to create more?
        </ModalUpgradePlan>
        {/* PERSONAL INFORMATION */}
        <div className="container-lg" style={{ marginBottom: 30 }}>
          <div
            className="title-purple"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span style={{ marginRight: 8}}> Current Plan </span>
              <span className={styles.badge}> { user.plan.name } </span> 
            </div>
            <Button
              size="large"
              className="primary-btn font-regular"
              onClick={() => navigate('/upgrade-plan')}
            >
              Manage Plans
              <ExportOutlined style={{marginLeft: 8, color: "#FFFFFF" }} />
            </Button>
          </div>
        </div>
        <div className="container-lg" style={{ marginBottom: 30 }}>
          <div
            className="title-purple"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ECECEC",
              paddingBottom: 5,
              marginBottom: 15,
            }}
          >
            Company profile
            {user.admin &&
              (editCompany ? (
                <Button
                  size="large"
                  className="primary-btn font-regular"
                  onClick={() => setEditCompany(false)}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  size="large"
                  className="primary-btn font-regular"
                  onClick={() => updateCompany()}
                >
                  Save
                </Button>
              ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <Avatar
                  style={{ marginRight: 20 }}
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={`${process.env.REACT_APP_HOST_NAME}/${companyState.image_url}`}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="title-purple">{companyState.name}</span>
                  {/*<span className='default-text'>{user.emailAddress}</span>*/}
                </div>
              </div>
              {user.admin && (
                <div>
                  <Button
                    size="large"
                    className="primary-btn-outline font-regular"
                    style={{ marginRight: 30 }}
                    onClick={() => getImageCompany()}
                  >
                    Upload Image
                  </Button>
                  <input
                    style={{ display: "none" }}
                    ref={inputHidden}
                    type="file"
                    accept="image/jpeg, image/png, image/jpg, image/gif"
                    onChange={(e) => updateCompanyImage(e.target.files[0])}
                  />
                  <Button
                    size="large"
                    onClick={() => updateCompanyImage(false)}
                    className="primary-btn font-regular"
                  >
                    Delete Image
                  </Button>
                </div>
              )}
            </div>
            <div className={styles["inputs-container"]} style={{ flex: 1 }}>
              <WInput
                label="Company name"
                value={companyState.name}
                placeholder="John"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setCompanyState({ ...companyState, name: value })
                }
                disabled={editCompany}
              />
              <WInput
                label="Website"
                value={companyState.website}
                placeholder="https://www.weyzo.io"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setCompanyState({ ...companyState, website: value })
                }
                disabled={editCompany}
              />
              <WInput
                label="Phone"
                value={companyState.phone}
                placeholder="+34 666777888"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setCompanyState({ ...companyState, phone: value })
                }                
                disabled={editCompany}
              />
              <WInput
                label="Industry"
                value={companyState.industry}
                placeholder="Software & technology"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setCompanyState({ ...companyState, industry: value })
                }                
                disabled={editCompany}
              />
              <WInput
                label="Linkedin"
                value={companyState.linkedin}
                placeholder="https://www.linkedin.com/company/weyzo/"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setCompanyState({ ...companyState, linkedin: value })
                }                
                disabled={editCompany}
              />
              <WInput
                label="Address"
                value={companyState.address}
                placeholder="Street 1, 2ºA"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setCompanyState({ ...companyState, website: value })
                }                
                disabled={editCompany}
              />
              <WInput
                label="Postal code"
                value={companyState.postal_code}
                placeholder="08000"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setCompanyState({ ...companyState, postal_code: value })
                }                
                disabled={editCompany}
              />
              <WInput
                label="City"
                value={companyState.city}
                placeholder="Barcelona"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setCompanyState({ ...companyState, city: value })
                }                
                disabled={editCompany}
              />
              <WInput
                label="Province"
                value={companyState.province}
                placeholder="Barcelona"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setCompanyState({ ...companyState, province: value })
                }                
                disabled={editCompany}
              />
              <WInput
                label="Country"
                value={companyState.country}
                placeholder="Spain"
                style={{ marginBottom: 20, marginRight: 20 }}
                onChangeValue={(value) =>
                  setCompanyState({ ...companyState, country: value })
                }                
                disabled={editCompany}
              />
            </div>
          </div>
        </div>

        <div className="container-lg" style={{ marginBottom: 30 }}>
          <div
            className="title-purple"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ECECEC",
              paddingBottom: 5,
              marginBottom: 15,
            }}
          >
            <div>
              <RobotOutlined style={{ marginRight: 8, color: "#8847E6", fontSize: 20 }} />
              Target Audience
            </div>
            {user.admin &&
              (editCompany ? (
                <Button
                  size="large"
                  className="primary-btn font-regular"
                  onClick={() => setEditCompany(false)}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  size="large"
                  className="primary-btn font-regular"
                  onClick={() => updateCompany()}
                >
                  Save
                </Button>
              ))
            }
          </div>
          <WTextarea
            value={companyState.target_audience}
            placeholder="Company target audience"
            style={{ marginBottom: 20, marginRight: 20 }}
            onChangeValue={(value) =>
              setCompanyState({ ...companyState, country: value })
            }
            disabled={editCompany}
            rows={10}
          />
          <small style={{ display: 'flex', justifyContent: 'center', color: '#4A5568', fontStyle: 'italic' }}>
            Text generated by the AI Engine to describe the target audience of the company
          </small>
        </div>

        {/* ADMIN USERS MANAGEMENT */}
        {user.admin && (
          <div className="container-lg" style={{ marginBottom: 30 }}>
            <div
              style={{
                borderBottom: "1px solid #ECECEC",
                paddingBottom: 5,
                marginBottom: 15,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  className="title-purple"
                  style={{ margin: 0, marginRight: 20 }}
                >
                  Users
                </p>
                <p
                  className="font-bold"
                  style={{
                    margin: 0,
                    fontSize: 16,
                    color: "var(--gray-placeholder-light)",
                  }}
                >
                  <span style={{ color: "var(--gray-700)" }}>
                    {users && users.length}
                  </span>
                </p>
              </div>
              <div>
                <Button
                  className="primary-btn font-bold"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={addNewUser}
                >
                  <PlusOutlined style={{ marginRight: 8 }} />
                  <p className="font-bold" style={{ margin: 0 }}>
                    Add New User
                  </p>
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  style={{ marginRight: 20 }}
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={`${process.env.REACT_APP_HOST_NAME}/${user.image_url}`}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="title-purple">{user.full_name}</span>
                  <span className="default-text">{user.email}</span>
                </div>
              </div>
              <Divider type="vertical" style={{ height: "3em" }} />
              <div>
                <p
                  className="font-medium"
                  style={{ margin: 0, color: "var(--gray-placeholder-light)" }}
                >
                  User Type
                </p>
                <p
                  className="font-medium"
                  style={{ margin: 0, color: "var(--gray-700)" }}
                >
                  {translate_user_type(user.user_type)}
                </p>
              </div>
              <Divider type="vertical" style={{ height: "3em" }} />
              <div>
                <p
                  className="font-medium"
                  style={{ margin: 0, color: "var(--gray-placeholder-light)" }}
                >
                  Calendar Connection
                </p>

                {user.calendar_id !== null && (
                  <p
                    className="font-medium"
                    style={{ margin: 0, color: "var(--gray-700)" }}
                  >
                    Connected
                  </p>
                )}
                {user.calendar_id === null && (
                  <p
                    className="font-medium"
                    style={{ margin: 0, color: "var(--gray-700)" }}
                  >
                    Disconected
                  </p>
                )}
              </div>
              <div>
                <Button
                  className="primary-btn-outline-round font-regular"
                  onClick={() =>
                    navigate("/profile", { state: { edit: true } })
                  }
                >
                  Edit Profile
                </Button>
              </div>
            </div>
            <div>
              <div
                className="font-medium"
                style={{
                  color: "var(--gray-700)",
                  fontSize: 16,
                  borderBottom: "1px solid #ECECEC",
                  marginBottom: 20,
                  marginTop: 25,
                  paddingBottom: 5,
                }}
              >
                Team Users
              </div>
              <div>
                {users != null &&
                  users.map((team_user, i) => {
                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        className={`container-clickable-card ${styles["container-users"]}`}
                        onClick={() => navigate(`/team-user/${team_user.id}`)}
                        key={team_user.id}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            minWidth: 200,
                            maxWidth: 200,
                          }}
                        >
                          {team_user.image.includes("amazonaws") && (
                            <Avatar
                              style={{ marginRight: 20 }}
                              size="default"
                              src={team_user.image}
                            />
                          )}
                          <div>
                            <p
                              className="font-bold"
                              style={{ color: "var(--gray-700)", margin: 0 }}
                            >
                              {team_user.full_name}
                            </p>
                            <p
                              className="font-medium"
                              style={{ color: "var(--gray-700)", margin: 0 }}
                            >
                              {team_user.email}
                            </p>
                          </div>
                        </div>
                        <Divider type="vertical" style={{ height: "3em" }} />
                        <div style={{ minWidth: 100, maxWidth: 100 }}>
                          <p
                            className="font-medium"
                            style={{
                              margin: 0,
                              color: "var(--gray-placeholder-light)",
                            }}
                          >
                            User Role
                          </p>
                          <p
                            className="font-medium"
                            style={{ margin: 0, color: "var(--gray-700)" }}
                          >
                            {translate_user_type(team_user.user_type)}
                          </p>
                        </div>
                        <Divider type="vertical" style={{ height: "3em" }} />
                        <div style={{ minWidth: 140, maxWidth: 140 }}>
                          <p
                            className="font-medium"
                            style={{
                              margin: 0,
                              color: "var(--gray-placeholder-light)",
                            }}
                          >
                            Calendar Connection
                          </p>
                          <p
                            className="font-medium"
                            style={{
                              margin: 0,
                              color: "var(--gray-700)",
                            }}
                          >
                            {team_user.calendar_id !== null && team_user.calendar_id !== ""
                              ? "Connected"
                              : "Disconnected"}
                          </p>
                        </div>
                        <Divider type="vertical" style={{ height: "3em" }} />
                        <div style={{ minWidth: 130, maxWidth: 130 }}>
                          <p
                            className="font-medium"
                            style={{
                              margin: 0,
                              color: "var(--gray-placeholder-light)",
                            }}
                          >
                            Last Sign In
                          </p>
                          <p
                            className="font-medium"
                            style={{
                              margin: 0,
                              color: "var(--gray-700)",
                            }}
                          >
                            {Moment(team_user.lastSeenAt).format("DD-MM-YYYY - H:m")}
                          </p>
                        </div>
                        <div>
                          <ChevronLeftPurple />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
}

export default CompanyProfile;
