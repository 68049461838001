import React, { useState } from "react";
import axios from "axios";
import { Form, Input, Button } from "antd";
import { message } from "antd";
import styles from "./password-reset.module.css";
import { BASE_URL } from "../../baseURL";
import { useParams, useNavigate } from "react-router-dom";
import WeyzoLogo from "../../assets/img/WeyzoLogo.svg";

function PasswordReset() {
  const [passwordSent, setPasswordSent] = useState(false);

  let navigate = useNavigate();

  const { token } = useParams();

  const onFinish = (values) => {
    if (values.password1.length < 6) {
      message.error("Password must be at least 6 characters");
      return;
    }

    if (values.password1 !== values.password2) {
      message.error("Passwords do not match");
      return;
    }

    changePassword(values);
  };

  const changePassword = async (values) => {
    try {
      await axios.post(`${BASE_URL}/api/v1/entrance/update-password-and-login`, {
        password: values.password1,
        token: token,
      });
      navigate("/passwordChanged");
      setPasswordSent(true);
    } catch (error) {
      message.error("Error changing password");
      console.error(error.message);
    }
  };

  return (
    <>
      <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
        <img
          style={{ width: "auto", height: "auto", marginBottom: 140 }}
          src={WeyzoLogo}
          alt="WeyzoLogo"
        />
        <div style={{ fontWeight: "bold" }} className="medium-text">
          Reset your password
        </div>
        <div>Enter a new password below to change password</div>
      </div>
      <div className={styles.loginContainer}>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="password1"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input.Password
              className={styles.Input}
              label="Password 1:"
              placeholder="Enter new password"
              type="text"
            />
          </Form.Item>

          <Form.Item
            name="password2"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              className={styles.Input}
              label="Password 2:"
              placeholder="Confirm new password"
              type="text"
              style={{ marginBottom: 15 }}
            />
          </Form.Item>

          <div
            style={{
              border: "1px solid #CCCCCC",
              marginBottom: 30,
              paddingTop: 7,
              paddingBottom: 7,
              paddingLeft: 15,
              borderRadius: 10,
            }}
          >
            <p
              className="font-regular"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              Your password must contain:
            </p>
            <li className="font-regular" style={{ listStyle: "circle" }}>
              At least 8 characters
            </li>
          </div>

          <Form.Item>
            <Button
              size="large"
              className="primary-btn font-regular full-btn"
              htmlType="submit"
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default PasswordReset;
