import React, { useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { register } from "services/auth";

import WeyzoLogo from "../../assets/img/WeyzoLogo.svg";
import styles from "../login/login.module.css";
import stylesRegister from "./register.module.css";
import { BASE_URL } from "../../baseURL";

function Register() {
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verify, setVerify] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  const goLogin = () => {
    navigate("/login");
  };

  const onFinish = () => {
    setLoading(true);
    register(email, password)
      .then((res) => {
        setLoading(false);
        setVerify(true);
        setId(res.id);
      })
      .catch((err) => {
        console.log("ERROR", err);
        if (err.response.status === 409) {
          message.error("This email already exist.");
        }
      });
  };

  const resendEmail = async () => {
    return await fetch(`${BASE_URL}/resend_activation_code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("response check", json);
        message.success("Email sent correctly.");
        return json;
      })
      .catch((error) => {
        message.error("Error to sent email.");
        console.error(error.message);
      });
  };

  return !verify ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 40,
      }}
    >
      <img
        style={{ width: "auto", height: "auto", marginBottom: 140 }}
        src={WeyzoLogo}
        alt="WeyzoLogo"
      />

      <h1 className={"font-bold"} style={{ fontSize: 30 }}>
        Create your account
      </h1>
      <div className={stylesRegister.containerForm}>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              className={styles.Input}
              darkback="true"
              label="Work Email:"
              placeholder="Type your work email"
              type="text"
              value={email}
              onChange={(value) => setEmail(value.target.value)}
            />
          </Form.Item>

          {email !== "" && (
            <>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  className={styles.Input}
                  darkback="true"
                  label="Password:"
                  placeholder="Password"
                  type="text"
                  value={password}
                  onChange={(value) => setPassword(value.target.value)}
                  style={{ marginBottom: 15 }}
                />
              </Form.Item>

              <div
                style={{
                  border: "1px solid #CCCCCC",
                  marginBottom: 30,
                  paddingTop: 7,
                  paddingBottom: 7,
                  paddingLeft: 15,
                  borderRadius: 10,
                }}
              >
                <p
                  className="font-regular"
                  style={{ marginTop: 5, marginBottom: 5 }}
                >
                  Your password must contain:
                </p>
                <li className="font-regular" style={{ listStyle: "circle" }}>
                  At least 8 characters
                </li>
              </div>
            </>
          )}

          <Button
            size="large"
            style={{ height: 46 }}
            className="primary-btn font-bold full-btn"
            htmlType="submit"
          >
            {loading ? <Spin /> : 'Continue'}
          </Button>
        </Form>
      </div>

      <div style={{ marginTop: 30 }}>
        <span className="font-medium">
          Already have an account?
          <Button
            type="text"
            className="font-regular"
            style={{ color: "#6a42e9" }}
            onClick={goLogin}
          >
            Log in
          </Button>
        </span>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 40,
      }}
    >
      <img
        style={{ width: "auto", height: "auto", marginBottom: 140 }}
        src={WeyzoLogo}
        alt="WeyzoLogo"
      />

      <h1 className={"font-bold"} style={{ fontSize: 30 }}>
        Verify your email
      </h1>

      <div
        style={{
          width: 200,
          textAlign: "center",
          color: "#4A5568",
          fontSize: 14,
        }}
      >
        <p>We sent email to {email}. Click the link inside to get started.</p>
      </div>

      <span className="font-medium">
        {" "}
        <Button type="text" className="font-regular" onClick={resendEmail}>
          Resend email
        </Button>
      </span>
    </div>
  );
}

export default Register;
