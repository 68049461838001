import React, { useState, useEffect, createRef } from "react";
import { Skeleton, message } from "antd";
import "./uploadInput.module.css";
import styles from "./uploadInput.module.css";

const UploadInput = ({ setLogo, logo }) => {
  const [value, setValue] = useState();
  const [preview, setPreview] = useState();
  const fileRef = createRef();

  useEffect(() => {
    let objectUrl;
    if (value) {
      objectUrl = URL.createObjectURL(value);
      setPreview(objectUrl);
    }

    return () => URL.revokeObjectURL(objectUrl);
  }, [value]);

  const onClick = () => {
    fileRef.current.click();
  };

  const handleFileChange = async (file) => {
    if (file) {
      try {
        const base64Image = await uploadImage(file);
        setLogo(base64Image);
        setValue(file);
      } catch (error) {
        message.error("Error uploading image");
        console.error(error.message);
      }
    }
  };

  const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div id="upload-container" className={styles["upload-container"]}>
      <div id="upload-preview" className={styles["upload-preview"]}>
        {value || logo ? (
          <img src={value ? preview : `${process.env.REACT_APP_HOST_NAME}/${logo}`} alt="logo-preview" />
        ) : (
          <Skeleton.Image />
        )}
      </div>
      <div id="upload-input-container">
        <input
          style={{ display: "none" }}
          className={styles.upload}
          ref={fileRef}
          type="file"
          accept="image/jpeg, image/png, image/jpg, image/gif"
          onChange={(e) => handleFileChange(e.target.files[0])}
        />
        <button type="button" onClick={onClick} className={styles["upload-button"]}>
          Upload Image
        </button>
      </div>
    </div>
  );
};

export default UploadInput;
