import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUser } from "../../context/UserContext";
import { message } from "antd";

import { updateFormData, getForm } from "../../services/formService";

import Form from "./Form";

import Loading from "components/Loading/Loading";

import {
  generateFixedQuestions,
  generateCustomFixedQuestions,
  generateQualifyingQuestions
} from "./utils/generateQuestions.js";

const EditForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUser();
  const methods = useForm();
  const [fixedQuestions, setFixedQuestions] = useState(null);
  const [customFixedQuestions, setCustomFixedQuestions] = useState(null);
  const [qualifyingQuestions, setQualifyingQuestions] = useState(null);
  const [form, setForm] = useState(null);
  const [error, setError] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [logo, setLogo] = useState();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      onDraft(methods.getValues());
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setPageLoading(true);
    getData();
   }, []);

  const getData = async () => {
    try {
      const data = await getForm({ id: params.id });
      const formId = data.id;
      setForm({ id: formId, formData: data });
      methods.setValue("title", data.title);
      methods.setValue("subtitle", data.subtitle);
      setFixedQuestions(generateFixedQuestions(data.fixed_questions))
      setCustomFixedQuestions(generateCustomFixedQuestions(data.custom_fixed_questions));
      setQualifyingQuestions(generateQualifyingQuestions(data.qualifying_questions));
      setLogo(data.logo_url);
    } catch (err) {
      setError(err);
    } finally {
      setPageLoading(false);
    }
  };

  const onDraft = async (data) => {
    saveForm(data, 'draft');
  };

  const onPublish = async (data) => {
    saveForm(data, 'active');
  };

  const saveForm = async (data, status) => {
    setSubmitLoading(true);
    setPageLoading(true);
    const updatedFixedQuestions = 
      data.fixedQuestions && fixedQuestions.map((question, index) => {
      if (data.fixedQuestions[question.key]) {
        const { key, ...questionWithoutKey } = question;
        return {
          ...questionWithoutKey,
          order: question.order,
          active: data.fixedQuestions[question.key].active,
          required: data.fixedQuestions[question.key].required,
        };
      }
      return question;
    });

    const updatedCustomFixedQuestions = data.customFixedQuestions &&
      customFixedQuestions
      .filter(question => Object.keys(question).length > 0 && question.label)
      .map((question, index) => {
        if (data.customFixedQuestions[question.key]) {
          const { key, ...questionWithoutKey } = question;
          return {
            ...questionWithoutKey,
            name: data.customFixedQuestions[question.key].label.toLowerCase().replace(/\s+/g, '-'),
            order: question.order,
            active: question.active,
            placeholder: data.customFixedQuestions[question.key].label,
            label: data.customFixedQuestions[question.key].label,
            value_type: question.value_type,
            required: data.customFixedQuestions[question.key].required,
          };
        }
        return question;
      }
    );

    const updatedQualifyingQuestions = data.qualifyingQuestions &&
      qualifyingQuestions
      .filter(question => Object.keys(question).length > 0)
      .map((question, index) => {
        if (data.qualifyingQuestions[question.key]) {
          const { key, ...questionWithoutKey } = question;
          return {
            ...questionWithoutKey,
            name: data.qualifyingQuestions[question.key].label.toLowerCase().replace(/\s+/g, '-'),
            order: question.order,
            active: question.active,
            placeholder: data.qualifyingQuestions[question.key].label,
            label: data.qualifyingQuestions[question.key].label,
            required: data.qualifyingQuestions[question.key].required,
            question_options_attributes: question.question_options.map((option, index) => {
              const { key, ...optionWithoutKey } = option;
              if (data.qualifyingQuestions[question.key].question_options[key]) {
                return {
                  ...optionWithoutKey,
                  order: option.order,
                  active: option.active,
                  value: data.qualifyingQuestions[question.key].question_options[key].value
                };
              }
              return optionWithoutKey;
            })
          };
        }
        return question;
      }
    );

    const formData = {
      title: data.title,
      subtitle: data.subtitle,
      video_call: data.video_call,
      primary_color: data["design-primary-color"]?.hex,
      secondary_color: data["design-secondary-color"]?.hex,
      button_text: data["button-text"],
      button_background: data["button-background-color"]?.hex,
      button_text_color: data["button-text-color"]?.hex,
      watermark_disabled: data.watermark_disabled,
      legal_text: data["legal-text"],
      min_score: data.scoring,
      logo: logo,
      meeting_duration: data["meeting-duration"],
      meeting_title: data["meeting-title"],
      meeting_description: data["meeting-description"],
      status: form.formData.status === 'active' ? 'active' : status,
      questions_attributes: (updatedQualifyingQuestions ?? []).concat(updatedFixedQuestions ?? []).concat(updatedCustomFixedQuestions ?? [])
    };
    
    if (form.formData.status !== 'active') {
      formData.fixed_questions = updatedFixedQuestions;
      formData.custom_fixed_questions = updatedCustomFixedQuestions;
      formData.qualifying_questions = updatedQualifyingQuestions;
    }

    try {
      const response = await updateFormData({
        formData,
        formId: form.id,
      });
  
      setTimeout(() => {
        setPageLoading(false);
        setSubmitLoading(false);
        message.success(response.message);
        navigate('/forms', { state: { from: "EditForm" } });
      }, 1500);
    } catch (error) {
      setSubmitLoading(false);
      message.error("Oops, something went wrong");
      console.error(error.message);
    }
  };
    
  const renderLoading = () => {
    return (
      <>
        {pageLoading && (
          <div className="weyzo-loader-page-wrapper">
            {
              submitLoading && (
                <div>
                  <h2> Updating form, please wait </h2>
                </div>
              )
            }
            <div>
              <Loading />
            </div>
          </div>
        )}
      </>
    );
  };

  if (pageLoading) {
    return renderLoading();
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      { form && 
        (
          <Form
            formData={form.formData}
            loading={submitLoading}
            logo={logo}
            setLogo={setLogo}
            fixedQuestions={fixedQuestions}
            setFixedQuestions={setFixedQuestions}
            customFixedQuestions={customFixedQuestions}
            setCustomFixedQuestions={setCustomFixedQuestions}
            qualifyingQuestions={qualifyingQuestions}
            setQualifyingQuestions={setQualifyingQuestions}
            onPublish={onPublish}
            onDraft={onDraft}
          />
        )
      }
    </>
  );
};

export default EditForm;
