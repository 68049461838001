import React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

import "./buttons.module.css";
import styles from "./buttons.module.css";

import upgradeIcon from "../../assets/img/buttonsLogos/lightning-fill.svg";

export const UpgradeButton = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/upgrade-plan");
  };
  const renderButton = () => {
    return (
      <>
        <div className={styles["upgrade-container"]}>
          <span className={styles["upgrade-text"]}>Upgrade plan</span>
          <img
            src={upgradeIcon}
            alt="upgrade icon"
            className={styles["upgrade-icon"]}
          />
        </div>
      </>
    );
  };
  return (
    <>
      <button onClick={onClick} className={styles["upgrade-button"]}>
        {renderButton()}
      </button>
    </>
  );
};

export const UpgradePlanButton = ({ planSection, onClick, loading, trial, currentPlan }) => {
  console.log("planSection", currentPlan);
  const getButtonText = () => {
    if (trial.active) {
      return "Upgrade";
    }

    if (planSection === "starter" && (currentPlan === "professional" || currentPlan === "enterprise")) {
      return "Already included in Professional Plan";
    }

    switch (planSection) {
      case "starter":
        return "Current";
      case "professional":
        return "Current";
      case "enterprise":
        return "Current";
      default:
        return "Upgrade";
    }
  };

  const isDisabled = () => {
    if (trial.active) {
      return false;
    }

    return planSection === "starter" || planSection === "professional" || planSection === "enterprise";
  };

  const getButtonClass = () => {
    switch (planSection) {
      case "starter":
        return `${styles["plan-starter"]} ${styles["plan-button"]}`;
      case "professional":
        return `${styles["plan-pro"]} ${styles["plan-button"]}`;
      case "enterprise":
        return `${styles["plan-enterprise"]} ${styles["plan-button"]}`;
      default:
        return `${styles["plan-button"]}`;
    }
  };

  return (
    <button
      disabled={isDisabled()}
      onClick={onClick}
      className={getButtonClass()}
    >
      {loading ? <LoadingOutlined /> : getButtonText()}
    </button>
  );
};

export const UpgradeModalButton = ({ children }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/upgrade-plan");
  };
  return (
    <>
      <div>
        <button onClick={onClick} className={styles["upgrade-modal"]}>
          <p>{children}</p>
        </button>
      </div>
    </>
  );
};

export const UpgradeLeadsButton = ({ children }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/upgrade-plan");
  };
  return (
    <>
      <div>
        <button
          onClick={onClick}
          className={`${styles["upgrade-leads"]}`}
        >
          <p> {children} </p>
        </button>
      </div>
    </>
  );
};
