import axios from "axios";
import { AUTHENTICATED_URL } from "baseURL";
import { generateFixedQuestions } from "../views/FormBuilder/utils/generateQuestions"

export const createFormData = async ({
  formData,
  logo,
  fixedQuestions,
  qualifyingQuestions,
  customFixedQuestions,
  dataCustomFixedQuestions,
  status
}) => {
  const newQualifyingQuestions = qualifyingQuestions &&
    qualifyingQuestions.filter(question => Object.keys(question).length > 0 && question.label)
    .map((question, index) => {
      const { key, ...questionWithoutKey } = question;
      return {
        ...questionWithoutKey,
        name: question.label.toLowerCase().replace(/\s+/g, '-'),
        label: question.label,
        value: "true",
        value_type: "text",
        question_type_internal_name: "qualifying",
        active: true,
        required: true,
        order: question.order,
        placeholder: question.label,
        question_options_attributes: question.question_options.map((option, optionIndex) => {
          const { key, ...optionWithoutKey } = option;
          return {
            ...optionWithoutKey,
            order: optionIndex
          }
      })
    }
  });

  const defaultFixedQuestions = generateFixedQuestions();
  const newFixedQuestions = fixedQuestions && defaultFixedQuestions.map((question, index) => {
    if (fixedQuestions[question.key]) {
      const { key, ...questionWithoutKey } = question;
      return {
        ...questionWithoutKey,
        question_type_internal_name: "fixed",
        order: fixedQuestions[question.key].order,
        active: fixedQuestions[question.key].active,
        required: fixedQuestions[question.key].required,
      };
    }
    return question;
  });

  const newCustomFixedQuestions = dataCustomFixedQuestions &&
    customFixedQuestions
    .filter(question => Object.keys(question).length > 0 && question.label)
    .map((question, index) => {
      if (dataCustomFixedQuestions[question.key]) {

        const { key, ...questionWithoutKey } = question;
        return {
          ...questionWithoutKey,
          question_type_internal_name: "custom_fixed",
          name: dataCustomFixedQuestions[question.key].label.toLowerCase().replace(/\s+/g, '-'),
          order: question.order,
          active: question.active,
          placeholder: dataCustomFixedQuestions[question.key].label,
          label: dataCustomFixedQuestions[question.key].label,
          value_type: question.value_type,
          required: dataCustomFixedQuestions[question.key].required,
        };
      }
      return question;
    }
  );

  const newData = {
    title: formData.title,
    logo: logo,
    subtitle: formData.subtitle,
    domain: formData.domain,
    video_call: formData.video_call,
    meeting_title: formData["meeting-title"],
    meeting_duration: formData["meeting-duration"],
    meeting_description: formData["meeting-description"],
    legal_text: formData["legal-text"],
    min_score: formData.scoring,
    primary_color: formData["design-primary-color"] ? formData["design-primary-color"].hex : "#8847E6",
    secondary_color: formData["design-secondary-color"] ? formData["design-secondary-color"].hex : "#f2eaff",
    button_text: formData["button-text"] || "Request a Demo",
    button_background: formData["button-background-color"] ? formData["button-background-color"].hex : "#8847E6",
    button_text_color: formData["button-text-color"] ? formData["button-text-color"].hex : "#ffffff",
    status: status,
    watermark_disabled: formData.watermark_disabled,
    questions_attributes: (newQualifyingQuestions ?? []).concat(newFixedQuestions ?? []).concat(newCustomFixedQuestions ?? [])  
  };

  const token = localStorage.getItem("token");
  let response;
  try {
    response = await axios.post(`${AUTHENTICATED_URL}/forms`, newData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    console.error(err.message);
    response = err.response;
  }
  return response.data;
};

export const updateFormData = async ({ formData, formId }) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.patch(`${AUTHENTICATED_URL}/forms/${formId}`, { form: formData }, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

export const getForm = async ({ id }) => {
  const token = localStorage.getItem("token");
  let response
  try {
    response = await axios.get(`${AUTHENTICATED_URL}/forms/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
  } catch(err) {
    console.error(err.message)
    response = err.response
  }
  return response.data.form
}
