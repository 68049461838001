import React, { createContext, useContext, useState, useEffect } from 'react';
import { checkLogin } from '../services/auth';
import { useNavigate, useLocation } from 'react-router-dom';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [plan, setPlan] = useState(null);
  const [trial, setTrial] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      checkLogin()
        .then((response) => {
          setUser(response.current_user)
          setPlan(response.current_user.plan.internal_name)
          setTrial({
            expired: response.current_user.trial.trial_expired,
            active: response.current_user.trial.trial_active,
            days_left: response.current_user.trial.trial_days_left
          })

          if (response.current_user.trial.trial_expired && location.pathname !== '/upgrade-plan') {
            console.log('Trial expired');
            console.log(response)
            navigate('/trial-expired');
          }
        })
        .catch((error) => {
          console.error('Authentication error:', error);
          setUser(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setUser(null);
      setPlan(null);
      setTrial(null);
      setIsLoading(false);
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, plan, setPlan, trial, setTrial, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
