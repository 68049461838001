import axios from "axios";
import { AUTHENTICATED_URL } from "baseURL";

export const updateNotification = async ({ name, value }) => {
  const token = localStorage.getItem("token");

  let response;
  try {
    response = await axios.patch(
      `${AUTHENTICATED_URL}/notification_setting`,
      { notification_settings: { [name]: value } },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (err) {
    console.error(err.message);
    response = err.response;
  }

  return response;
};
