import { useEffect } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AUTHENTICATED_URL } from "baseURL"
import { useUser } from "context/UserContext";
import { message, Card } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import { ReactComponent as WeyzoLogo} from 'assets/img/weyzo_logo.svg';

import styles from './PaymentSuccess.module.css';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { user, plan, setPlan } = useUser();

  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    if (sessionId) {
      const upgradePlan = async () => {
        try {
          const response = await axios.post(`${AUTHENTICATED_URL}/payments/upgrade_plan`, {
            session_id: sessionId,
          }, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 200) {
            setPlan(response.data.new_plan);
            message.success('Plan upgraded successfully');
            navigate('/');
          } else {
            message.error('Plan upgrade failed');
            console.error('Plan upgrade failed');
          }
        } catch (error) {
          message.error('Error upgrading plan');
          console.error('Error upgrading plan:', error);
        }
      };

      upgradePlan();
    }
  }, [searchParams, navigate, setPlan]);

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <div>
          <WeyzoLogo className="logo" />
        </div>
        
        <h2>
          <CreditCardOutlined style={{marginRight: 8}} />
          Processing your payment...
        </h2>
      </Card>
    </div>
  )
};

export default PaymentSuccess;
