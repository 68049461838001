import React, { useState } from "react";
import { message } from "antd";

import "./upgradePlan.module.css";
import styles from "./upgradePlan.module.css";

import { UpgradePlanButton } from "components/Buttons/Buttons";
import { CheckIcon, NoCheckIcon } from "components/CheckIcon/CheckIcon";

import { getStripeUrl } from "services/api";

import circle from "../../assets/img/billingPlans/check-circle.svg";

const ProPlan = ({ user, trial, currentPlan }) => {
  const [loading, setLoading] = useState(false);
  const renderHeader = () => {
    return (
      <>
        <div
          className={`${styles["plan-header-container"]} ${styles["plan-pro-header"]}`}
        >
          <p className={styles["plan-pro-plan"]}>Profesional</p>
          <p className={styles["plan-pro-price"]}>159€ / month </p>
          <p className={styles["plan-pro-time"]}>6-20 seats</p>
        </div>
      </>
    );
  };
  const renderWorkspace = () => {
    return (
      <>
        <div className={styles["feat-pro-container"]}>
          <h3>Workspace:</h3>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Up to 5000 leads auto-qualified and enriched each month</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>From 6 to 20 seats</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Unlimited dynamic form</p>
          </div>
        </div>
      </>
    );
  };
  const renderFeatures = () => {
    return (
      <>
        <div className={styles["feat-pro-container"]}>
          <h3>Features</h3>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Google Calendar connection</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Advanced scheduling</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Real-time qualification</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Route & assign leads</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Lead management</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Embed code</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Full-page form with Weyzo's subdomain</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Analytics</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Remove Weyzo's watermark</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Notification & email reminder</p>
          </div>
          <div className={styles["list-item-pro-container"]}>
            <CheckIcon />
            <p>Round robin assignment</p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check-pro"]}>
              Full-page form with custom domain
            </p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check-pro"]}>
              Integrations with Salesforce, Hubspot and more
            </p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check-pro"]}>
              Route & assign leads within your CRM
            </p>
          </div>
          <div className={styles["list-item-container"]}>
            <NoCheckIcon />
            <p className={styles["no-check-pro"]}>
              Custom routing and assignment rules
            </p>
          </div>
          <div
            style={{ marginBottom: 0 }}
            className={styles["list-item-container"]}
          >
            <NoCheckIcon />
            <p className={styles["no-check-pro"]}>
              Dedicated Account Management
            </p>
          </div>
        </div>
      </>
    );
  };

  const navigateToStripeCheckout = async () => {
    setLoading(true);
    try {
      const response = await getStripeUrl();
      if (response.status === 200) {
        window.location.replace(response.data.url);
        return;
      } else {
        message.error("Error: Something went wrong");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={`${styles["plan-container"]} ${styles["plan-pro"]}`}>
        <div className={styles.popular}>
          <p>Most popular</p>
          <img src={circle} alt="Circle check" />
        </div>
        {renderHeader()}
        <UpgradePlanButton planSection="professional" onClick={navigateToStripeCheckout} loading={loading} trial={trial} currentPlan={currentPlan} />
        {renderWorkspace()}
        {renderFeatures()}
      </div>
    </>
  );
};
export default ProPlan;
